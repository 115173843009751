import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import {Buffer} from "buffer";

class main extends Component {

    state = {
        user: [],
        entries: [],
        perms: [],
        adminToDel: "",
        adminIdToDel: "",
        itemInvoiceId: "",
        itemInvoiceName: ""
    }

    async setupSuppliersHistory() {
        const self = this;
        function getMyDate(date) {
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#table2').DataTable({
            'columnDefs': [
                { "width": "100%", "targets": 2 },
                { className: 'dt-left', targets: 2 },
            ],
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                const targetId = $("#boxForSupplierId").val();
                // console.log(targetId);
                await axios.post('/invoice_details_table', {itemId:targetId, offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const user = resData[vCount].user;
                    const itemId = resData[vCount].item_id;
                    const itemName = resData[vCount].item_name;
                    const itemCost = resData[vCount].item_cost;
                    const itemPrice = resData[vCount].item_price;
                    const itemDiscount = resData[vCount].item_discount;
                    const finalPrice = resData[vCount].final_price;
                    const soldQty = resData[vCount].item_qty;
                    const qtyAfterSell = resData[vCount].item_qty_after;
                    const totalPrice = resData[vCount].total_price;
                    const profit = resData[vCount].profit;
                    const date = getMyDate(resData[vCount].date);
                    const status = resData[vCount].status;
                    const branch = resData[vCount].branch;
                    const customer = resData[vCount].customer;
                    const haveCodes = resData[vCount].have_codes;
                    const print = resData[vCount].print;

                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"
                    var showRetAndPay = true;
                    if(self.state.user.type === "employee" && user !== self.state.user.username) {
                        showRetAndPay = false;
                    }
                    if(self.state.user.access.includes("|34|") && status !== "returned" && showRetAndPay) {
                        buttons += "<a class='myAStyle' id='edit_invoice_item' invoiceUser='"+user+"' itemCustomer='"+customer+"' itemStatus='"+status+"' itemId='"+id+"' itemName='"+itemName+"' itemDiscount='"+itemDiscount+"' itemFinalPrice='"+totalPrice+"' itemQty='"+soldQty+"' itemPrice='"+itemPrice+"'>Update</a>";
                    }
                    if(self.state.user.access.includes("|35|") && status !== "returned" && showRetAndPay) {
                        buttons += "<a class='myAStyle' id='return_invoice_item' invoiceUser='"+user+"' itemCustomer='"+customer+"' itemStatus='"+status+"' itemId='"+id+"' itemName='"+itemName+"' itemDiscount='"+itemDiscount+"' itemFinalPrice='"+totalPrice+"' itemQty='"+soldQty+"' itemPrice='"+itemPrice+"'>Return</a>";
                    }
                    if(self.state.user.access.includes("|44|") && haveCodes === "yes" && print === "yes") {
                        buttons += "<a class='myAStyle' id='print_invoice_item_codes' itemId='"+id+"'>Print Codes</a>";
                    }
                    buttons += "</div></div>";

                    out.push([id, user, itemName, itemId, itemCost, itemPrice, itemDiscount+"%", finalPrice, soldQty, qtyAfterSell, totalPrice, profit, date, status, branch, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#print_invoice_item_codes");
        $(document).on("click", "#print_invoice_item_codes", function() {
            const itemId = $(this).attr("itemId");
            const encId = Buffer.from(itemId, "utf8").toString("base64");
            window.open("/print-items-codes/"+encId, "_blank");
        })
        $(document).off("click", "#edit_invoice_item");
        $(document).on("click", "#edit_invoice_item", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemDiscount = $(this).attr("itemDiscount");
            const itemFinalPrice = $(this).attr("itemFinalPrice");
            const itemQty = $(this).attr("itemQty");
            const itemBasePrice = $(this).attr("itemPrice");
            const itemStatus = $(this).attr("itemStatus");
            const itemCustomer = $(this).attr("itemCustomer");
            const invoiceTotalCashBox = $("#boxForTotalCashbox").val();
            const invoiceUser = $(this).attr("invoiceUser");
            self.setState({invoiceUser:invoiceUser, itemInvoiceName:itemName, itemInvoiceId:itemId, invoiceReceivedCashbox:invoiceTotalCashBox, invoiceStatus:itemStatus});
            $("#invoiceItemDiscount").val(itemDiscount);
            $("#invoiceItemPrice").val(itemFinalPrice);
            $("#invoiceItemQty").val(itemQty);
            $("#invoiceItemBasePrice").html(itemBasePrice);
            $("#invoiceItemOrigTotalPrice").html(itemFinalPrice);
            $("#invoiceItemStatus").html(itemStatus);
            $("#invoiceItemCustomer").html(itemCustomer);
            window.$("#updateItemInvoice").off("shown.bs.modal").off("hidden.bs.modal");
            window.$("#updateItemInvoice").on("shown.bs.modal", function () {
                $("#suppliersLogsHistory").css({"z-index": "1000"});
            }).on("hidden.bs.modal", function () {
                $("#suppliersLogsHistory").css({"z-index": "1050"});
                $("#suppliersLogsHistory").css({"overflow": "auto"});
            });
            self.setupRate();
            $("#inCashBoxIdUsd").val(0);
            $("#inCashBoxIdLbp").val(0);
            $("#itemDiscountShow").show();
            $("#itemTotalPriceShow").show();
            $("#itemQtyShow").show();
            $("#updateInvoiceBtnId").html("Update");

            $("#doUpdateCashBoxTr").hide();
            $(".doUpdateCashboxClass").show();
            window.$("#updateItemInvoice").modal("show");
        });
        $(document).off("click", "#return_invoice_item");
        $(document).on("click", "#return_invoice_item", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemDiscount = $(this).attr("itemDiscount");
            const itemFinalPrice = $(this).attr("itemFinalPrice");
            const itemQty = $(this).attr("itemQty");
            const itemBasePrice = $(this).attr("itemPrice");
            const itemStatus = $(this).attr("itemStatus");
            const itemCustomer = $(this).attr("itemCustomer");
            const invoiceTotalCashBox = $("#boxForTotalCashbox").val();
            const invoiceUser = $(this).attr("invoiceUser");
            self.setState({invoiceUser:invoiceUser, itemInvoiceName:itemName, itemInvoiceId:itemId, invoiceReceivedCashbox:invoiceTotalCashBox, invoiceStatus:itemStatus});
            $("#invoiceItemDiscount").val(itemDiscount);
            $("#invoiceItemPrice").val(itemFinalPrice);
            $("#invoiceItemQty").val(itemQty);
            $("#invoiceItemBasePrice").html(itemBasePrice);
            $("#invoiceItemOrigTotalPrice").html(itemFinalPrice);
            $("#invoiceItemStatus").html(itemStatus);
            $("#invoiceItemCustomer").html(itemCustomer);
            window.$("#updateItemInvoice").off("shown.bs.modal").off("hidden.bs.modal");
            window.$("#updateItemInvoice").on("shown.bs.modal", function () {
                $("#suppliersLogsHistory").css({"z-index": "1000"});
            }).on("hidden.bs.modal", function () {
                $("#suppliersLogsHistory").css({"z-index": "1050"});
                $("#suppliersLogsHistory").css({"overflow": "auto"});
            });
            self.setupRate();
            $("#inCashBoxIdUsd").val(0);
            $("#inCashBoxIdLbp").val(0);
            $("#invoiceItemPrice").val(0);
            $("#itemDiscountShow").hide();
            $("#itemTotalPriceShow").hide();
            $("#itemQtyShow").hide();
            $("#updateInvoiceBtnId").html("Return");
            $("#inCashBoxIdUsd").val(-invoiceTotalCashBox);
            if(itemStatus === "unpaid" && parseFloat(itemFinalPrice) >= parseFloat(invoiceTotalCashBox)) {
                $("#inCashBoxIdUsd").val(-invoiceTotalCashBox);
            }
            if(itemStatus === "paid" && parseFloat(invoiceTotalCashBox) > 0) {
                $("#inCashBoxIdUsd").val(-itemFinalPrice);
            }
            $("#doUpdateCashBoxTr").show();
            $(".doUpdateCashboxClass").hide();
            if($("#doUpdateCashbox").val() === "yes") {
                $(".doUpdateCashboxClass").show();
            }
            window.$("#updateItemInvoice").modal("show");
        });
    }

    setupDatatable() {
        const self = this;
        function getMyDate(date) {
            if(!date) {
                return "";
            }
            return new Date(date).toLocaleString("en-GB", {timeZone: self.state.user.tz, hour12: "true"}).toLocaleUpperCase();
        }
        function strtrunc(str, max, add){
            add = add || '...';
            return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
        };
        window.$('#table1').DataTable({
            'columnDefs': [
                // { className: "dt-left", "targets": 8 }
            ],
            order: [[0, "desc"]],
            serverSide: true,
            ordering: true,
            searching: true,
            processing: true,
            bDestroy: true,
            ajax: async function ( data, callback, settings ) {
                var fullCount
                var resData = []
                await axios.post('/invoices_table', {offset:data.start, limit:data.length, search:data.search.value, orderBy:data.order[0].column, orderDir:data.order[0].dir}).then((ok)=>{
                    fullCount = ok.data[0]
                    resData = ok.data[1]
                })
                var out = [];
                var vCount = 0
                data.length = resData.length
                for ( var i=data.start, ien=data.start+data.length ; i<ien ; i++ ) {
                    const id = resData[vCount].id;
                    const user = resData[vCount].user;
                    const sellType = resData[vCount].sell_type;
                    const payType = resData[vCount].pay_type;
                    const customer = resData[vCount].customer;
                    const itemsQty = resData[vCount].items_qty;
                    const basePrice = resData[vCount].base_price;
                    const finalPrice = resData[vCount].final_price;
                    const discountPercent = resData[vCount].discount_percent;
                    const totalCost = resData[vCount].total_cost;
                    const cashboxUsd = parseFloat(resData[vCount].cashbox_usd).toLocaleString("en", {useGrouping:true});
                    const cashboxLbp = parseFloat(resData[vCount].cashbox_lbp).toLocaleString("en", {useGrouping:true});
                    const profit = resData[vCount].profit;
                    const status = resData[vCount].status;
                    const date = getMyDate(resData[vCount].date);
                    const branch = resData[vCount].branch;
                    const totalCashbox = resData[vCount].total_cashbox;
                    const note = resData[vCount].note;

                    var buttons = "<div class='dropdown'>" +
                                        "<button class='dropbtn myBtnStyle'>Actions</button>" +
                                        "<div class='dropdown-content'>"
                    // if(self.state.user.access.includes("|24|")) {
                    //     buttons += "<a class='myAStyle' id='delete_branch' itemId='"+id+"' itemName='"+name+"' style='color: red !important;'>Delete</a>";
                    // }
                    // if(self.state.user.access.includes("|25|")) {
                    //     buttons += "<a class='myAStyle' id='edit_branch' itemId='"+id+"' itemName='"+name+"' itemPhone='"+phone+"' itemCountry='"+country+"' itemBalance='"+balance+"'>Update</a>";
                    // }
                    // if(self.state.user.access.includes("|25|")) {
                    //     buttons += "<a class='myAStyle' id='update_supplier_balance' itemId='"+id+"' itemName='"+name+"' itemBalance='"+balance+"'>Update Balance</a>";
                    // }
                    if(self.state.user.access.includes("|35|")) {
                        buttons += "<a class='myAStyle' id='view_supplier_logs' totalInCashBox='"+totalCashbox+"' itemId='"+id+"' itemCustomer='"+customer+"'>Items Details</a>";
                        buttons += "<a class='myAStyle' id='view_invoice_log' itemId='"+id+"'>Invoice History Log</a>";
                    }
                    var showRetAndPay = true;
                    if(self.state.user.type === "employee" && user !== self.state.user.username) {
                        showRetAndPay = false;
                    }
                    if(self.state.user.access.includes("|33|") && parseFloat(itemsQty) > 0 && showRetAndPay) {
                        buttons += "<a class='myAStyle' id='return_invoice' invoiceUser='"+user+"' cashBoxUsd='"+cashboxUsd+"' cashBoxLbp='"+cashboxLbp+"' totalInCashBox='"+totalCashbox+"' branch='"+branch+"' itemId='"+id+"' amount='"+finalPrice+"' status='"+status+"' customer='"+customer+"'>Return</a>";
                    }
                    if(self.state.user.access.includes("|39|") && status === "unpaid" && showRetAndPay) {
                        buttons += "<a class='myAStyle' id='pay_invoice' invoiceUser='"+user+"' totalInCashBox='"+totalCashbox+"' branch='"+branch+"' itemId='"+id+"' amount='"+finalPrice+"' status='"+status+"' customer='"+customer+"'>Pay Amount</a>";
                    }
                    if(self.state.user.access.includes("|44|")) {
                        buttons += "<a class='myAStyle' id='print_a4_invoice' itemId='"+id+"'>Print A4</a>";
                        buttons += "<a class='myAStyle' id='print_pos-80_invoice' itemId='"+id+"'>Print POS 80</a>";
                    }
                    if(self.state.user.access.includes("|55|")) {
                        buttons += "<a class='myAStyle' id='update_invoice_final_price' user='"+user+"' itemId='"+id+"' finalPrice='"+finalPrice+"' basePrice='"+basePrice+"'>Update Final Price</a>";
                    }

                    buttons += "</div></div>";
                    out.push([id, user, sellType, payType, customer, basePrice, discountPercent+"%", finalPrice, cashboxUsd+" / "+cashboxLbp+" ("+totalCashbox+")", profit, status, date, branch, note, buttons]);
                    vCount += 1
                }
                callback({
                    draw: data.draw,
                    data: out,
                    recordsTotal: fullCount,
                    recordsFiltered: fullCount
                });
            }
        });
        $(document).off("click", "#update_invoice_final_price");
        $(document).on("click", "#update_invoice_final_price", function() {
            const itemId = $(this).attr("itemId");
            const finalPrice = $(this).attr("finalPrice");
            const basePrice = $(this).attr("basePrice");
            const user = $(this).attr("user");
            $("#invoiceBasePriceIn").html(basePrice);
            self.setState({adminIdToDel:itemId, adminToDel:user});
            $("#invoiceFinalPriceIn").val(finalPrice);
            window.$("#updateInvoiceFinalPrice").modal("show");
        })
        $(document).off("click", "#print_pos-80_invoice");
        $(document).on("click", "#print_pos-80_invoice", function() {
            const itemId = $(this).attr("itemId");
            window.open("/print-pos-80/"+Buffer.from(itemId, "utf8").toString("base64"), "_blank");
        })
        $(document).off("click", "#print_a4_invoice");
        $(document).on("click", "#print_a4_invoice", function() {
            const itemId = $(this).attr("itemId");
            window.open("/print-a4/"+Buffer.from(itemId, "utf8").toString("base64"), "_blank");
        })
        $(document).off("click", "#view_invoice_log");
        $(document).on("click", "#view_invoice_log", function() {
            const itemId = $(this).attr("itemId");
            self.setState({adminIdToDel:itemId});
            axios.post("/get_invoice_history", {id:itemId}).then((ok)=>{
                window.toastr[ok.data.status](ok.data.msg);
                if(ok.data.status === "success") {
                    // res.send(JSON.stringify({status:"success", msg:"Success", itemId:itemId, itemAuthor:itemAuthor, itemOperation:itemOperation, itemDate:itemDate, itemDetails:itemDetails}));
                    const itemId = ok.data.itemId;
                    const itemAuthor = ok.data.itemAuthor;
                    const itemOperation = ok.data.itemOperation;
                    const itemDate = getMyDate(ok.data.itemDate);
                    const itemDetails = ok.data.itemDetails;
                    $("#setLogIdHistory").html(itemId);
                    $("#setAuthorHistory").html(itemAuthor);
                    $("#setOperationHistory").html(itemOperation);
                    $("#setDateHistory").html(itemDate);
                    $("#setDetailsHistory").val(itemDetails);
                    window.$("#invoiceHistoryModal").modal("show");
                }
            });
        });
        $(document).off("click", "#pay_invoice");
        $(document).on("click", "#pay_invoice", function() {
            const itemId = $(this).attr("itemId");
            const amount = $(this).attr("amount");
            const status = $(this).attr("status");
            const customer = $(this).attr("customer");
            const branch = $(this).attr("branch");
            const totalInCashBox = $(this).attr("totalInCashBox");
            const invoiceUser = $(this).attr("invoiceUser");
            self.setupRate();
            const leftCashboxInput = String(parseFloat(amount) - parseFloat(totalInCashBox));
            if(status === "unpaid") {
                $("#cashBoxIdUsdPayInvoice").val(leftCashboxInput);
            }
            $("#cashBoxIdLbpPayInvoice").val(0);
            $("#boxForTotalCashbox").val(totalInCashBox);
            self.setState({invoiceUser:invoiceUser, invoiceReceivedCashbox:totalInCashBox, adminToDel:customer, adminIdToDel:itemId, invoiceAmount:amount, invoiceStatus:status, invoiceBranch:branch});
            window.$("#payInvoiceModal").modal("show");
        });
        $(document).off("click", "#return_invoice");
        $(document).on("click", "#return_invoice", function() {
            const itemId = $(this).attr("itemId");
            const amount = $(this).attr("amount");
            const status = $(this).attr("status");
            const customer = $(this).attr("customer");
            const branch = $(this).attr("branch");
            const totalInCashBox = $(this).attr("totalInCashBox");
            const invoiceUser = $(this).attr("invoiceUser");
            const cashBoxUsd = $(this).attr("cashBoxUsd");
            const cashBoxLbp = $(this).attr("cashBoxLbp");
            self.setState({invoiceReceivedCashboxUsd:cashBoxUsd});
            self.setState({invoiceReceivedCashboxLbp:cashBoxLbp});
            self.setupRate();
            if(status === "paid") {
                $("#inCashBoxIdUsdInvoice").val(-totalInCashBox);
            } else if(status === "unpaid") {
                $("#inCashBoxIdUsdInvoice").val(-totalInCashBox);
            }
            $("#inCashBoxIdLbpInvoice").val(0);
            $("#boxForTotalCashbox").val(totalInCashBox);
            self.setState({invoiceUser:invoiceUser, invoiceReceivedCashbox:totalInCashBox, adminToDel:customer, adminIdToDel:itemId, invoiceAmount:amount, invoiceStatus:status, invoiceBranch:branch});
            window.$("#returnInvoiceModal").modal("show");
        });
        $(document).off("click", "#view_supplier_logs");
        $(document).on("click", "#view_supplier_logs", function() {
            const itemId = $(this).attr("itemId");
            const itemCustomer = $(this).attr("itemCustomer");
            const totalInCashBox = $(this).attr("totalInCashBox");
            self.setState({adminToDel:itemCustomer, adminIdToDel:itemId, itemIdLogsHistory:itemId, invoiceReceivedCashbox:totalInCashBox});
            $("#boxForSupplierId").val(itemId);
            $("#boxForTotalCashbox").val(totalInCashBox);
            window.$("#suppliersLogsHistory").modal("show");
            self.setupSuppliersHistory();
        });
        $(document).off("click", "#delete_branch");
        $(document).on("click", "#delete_branch", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            window.$("#deleteBranchModal").modal("show");
        });

        $(document).off("click", "#edit_branch");
        $(document).on("click", "#edit_branch", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemPhone = $(this).attr("itemPhone");
            const itemCountry = $(this).attr("itemCountry");
            const itemBalance = $(this).attr("itemBalance");
            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            $("#nameInput").val(itemName);
            $("#phoneInput").val(itemPhone);
            $("#countryInput").val(itemCountry);
            $("#balanceInput").val(itemBalance);
            $("#addAdminBtn").hide();
            $("#editAdminBtn").show();
            window.$("#addBranchModal").modal("show");
        });
        $(document).off("click", "#update_supplier_balance");
        $(document).on("click", "#update_supplier_balance", function() {
            const itemId = $(this).attr("itemId");
            const itemName = $(this).attr("itemName");
            const itemBalance = $(this).attr("itemBalance");
            self.setState({adminToDel:itemName, adminIdToDel:itemId});
            $("#setSupplierName").html(itemName);
            $("#setSupplierBalance").html(itemBalance);
            window.$("#updateBalanceModal").modal("show");
        });
    }
    // retBufferData = (str, origData, newData) => {
    //     return Buffer.from(str, origData).toString(newData);
    // }
    componentWillUnmount() {
        // console.log($('link[title="myPrintA4Style"]').remove());
        // $("head").remove('<link rel="stylesheet" href="/assets/style/print_a4.css" />');
    }
    componentDidMount() {
        axios.post("/is_authed").then((ok)=>{
            if(!ok.data) {
                this.props.history.push("/login");
                return;
            }
            this.setState({user:ok.data});
            if(!ok.data.access.includes("|32|")) {
                this.props.history.push("/panel");
                return;
            }
            const nodeName = "#invoices_btn";
            const interv3 = setInterval(function() {
                $(".MuiButtonBase-root").css({backgroundColor: ""});
                $(nodeName).css({backgroundColor: "cadetblue"});
                if($(nodeName).prop("style")) {
                    if($(nodeName).prop("style").backgroundColor === "cadetblue") {
                        clearInterval(interv3);
                    }
                }
            }, 1);
            this.setupDatatable();
            // $("head").append('<link rel="stylesheet" title="myPrintA4Style" href="/assets/style/print_a4.css" />');

            // if(ok.data.access.includes("|23|")) {
            //     this.setState({addAdminsBtn:[
            //         <>
            //             <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
            //                 $("#addAdminBtn").show();
            //                 $("#editAdminBtn").hide();
            //                 if(!$("#balanceInput").val()) {
            //                     $("#balanceInput").val(0);
            //                 }
            //                 window.$("#addBranchModal").modal("show");
            //             }}>+ Add</button>
            //         </>
            //     ]})
            // }
        })
    }

    addBranch = () => {
        const name = $("#nameInput").val();
        const phone = $("#phoneInput").val();
        const country = $("#countryInput").val();
        const balance = $("#balanceInput").val();
        if(!name) {
            window.toastr["error"]("Invalid Supplier Name");
            return;
        }
        axios.post("/add_supplier", {name:name, phone:phone, country:country, balance:balance}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addBranchModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    deleteBranch = () => {
        const name = this.state.adminToDel;
        const id = this.state.adminIdToDel;
        axios.post("/delete_supplier", {name:name, id:id}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#deleteBranchModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    editBranch = () => {
        const prevName = this.state.adminToDel;
        const id = this.state.adminIdToDel;
        const name = $("#nameInput").val();
        const phone = $("#phoneInput").val();
        const country = $("#countryInput").val();
        const balance = $("#balanceInput").val();
        if(!name) {
            window.toastr["error"]("Invalid Branch Name");
            return;
        }
        axios.post("/edit_supplier", {prevName:prevName, name:name, id:id, phone:phone, country:country, balance:balance}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#addBranchModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    updateSupplierBalance = (type) => {
        const name = this.state.adminToDel;
        const id = this.state.adminIdToDel;
        const balance = $("#balanceToChangeIn").val();
        const note = $("#balanceChangeNote").val();
        if(!balance || parseFloat(balance) < 0) {
            window.toastr["error"]("Invalid Balance Value");
            return;
        }
        if(!note) {
            window.toastr["error"]("Invalid Note Value");
            return;
        }
        var api = "/add_supplier_balance";
        if(type === "-") {
            api = "/remove_supplier_balance";
        }
        axios.post(api, {name:name, id:id, balance:balance, note:note}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$("#updateBalanceModal").modal("hide");
                window.$('#table1').DataTable().ajax.reload(null, false);
            }
        })
    }

    getValAfterDis = (val, dis) => {
        const newPrice = String(parseFloat(val) - (parseFloat(val) / 100 * parseFloat(dis)));
        return newPrice;
    }

    processInvoiceItemChanges = () => {
        var disVal = $("#invoiceItemDiscount").val();
        var qtyVal = $("#invoiceItemQty").val();
        if(!disVal) {
            $("#invoiceItemDiscount").val(0);
        }
        if(!qtyVal || parseFloat(qtyVal) < 1 || String(qtyVal).includes(".")) {
            $("#invoiceItemQty").val(1);
        }
        disVal = $("#invoiceItemDiscount").val();
        qtyVal = $("#invoiceItemQty").val();
        const basePrice = $("#invoiceItemBasePrice").html();
        const newPrice = this.getValAfterDis(basePrice, disVal);
        $("#invoiceItemPrice").val(parseFloat(newPrice) * parseFloat(qtyVal));
        this.processCashbox();
    }

    processCashbox = () => {
        const origTotalPrice = $("#invoiceItemOrigTotalPrice").html();
        const newTotalPrice = $("#invoiceItemPrice").val();
        const diffCashbox = String(parseFloat(newTotalPrice) - parseFloat(origTotalPrice));
        $("#inCashBoxIdUsd").val(diffCashbox);
        this.setLbpDiff();
        // console.log();
    }

    fixInputValue = (id) => {
        const val = $(id).val();
        if(parseFloat(val) < 0) {
            $(id).val(0);
        }
        if(!val) {
            $(id).val(0);
        }
        if(val.length > 1 && val[0] === "0") {
            $(id).val(val.slice(1));
        }
    }

    Sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    sleep = async(secs) => {
        await this.Sleep(secs * 1000);
    }

    pLog = async(color, text) => {
        $("#errorLabel").css({color: color});
        $("#errorLabel").html(text);
        $("#errorLabel").css({display: ""});

        const interval = 75;
        for(var i = 0; i < 10; ++i) {
            await this.Sleep(interval);
            $("#errorLabel").css({color: "black"});
            await this.Sleep(interval);
            $("#errorLabel").css({color: "red"});
        }
        $("#errorLabel").css({color: color});
    }
    pLog2 = async(color, text) => {
        $("#errorLabelInvoice").css({color: color});
        $("#errorLabelInvoice").html(text);
        $("#errorLabelInvoice").css({display: ""});

        const interval = 75;
        for(var i = 0; i < 10; ++i) {
            await this.Sleep(interval);
            $("#errorLabelInvoice").css({color: "black"});
            await this.Sleep(interval);
            $("#errorLabelInvoice").css({color: "red"});
        }
        $("#errorLabelInvoice").css({color: color});
    }
    pLog3 = async(color, text) => {
        $("#errorLabelPayInvoice").css({color: color});
        $("#errorLabelPayInvoice").html(text);
        $("#errorLabelPayInvoice").css({display: ""});

        const interval = 75;
        for(var i = 0; i < 10; ++i) {
            await this.Sleep(interval);
            $("#errorLabelPayInvoice").css({color: "black"});
            await this.Sleep(interval);
            $("#errorLabelPayInvoice").css({color: "red"});
        }
        $("#errorLabelPayInvoice").css({color: color});
    }

    updateInvoiceItem = () => {
        const cmd = $("#updateInvoiceBtnId").html();
        const itemId = this.state.itemInvoiceId;
        const itemName = this.state.itemInvoiceName;
        const itemBasePrice = $("#invoiceItemBasePrice").html();
        const itemDiscount = $("#invoiceItemDiscount").val();
        const itemPrice = $("#invoiceItemPrice").val();
        const origTotalPrice = $("#invoiceItemOrigTotalPrice").html();
        const correctTotalCashbox = String(parseFloat(itemPrice) - parseFloat(origTotalPrice));
        const itemQty = $("#invoiceItemQty").val();
        const itemPayStatus = $("#invoiceItemStatus").html();
        const invoiceItemCustomer = $("#invoiceItemCustomer").html();
        const invoiceUser = this.state.invoiceUser;
        if(!itemDiscount) {
            window.toastr["error"]("Invalid itemDiscount value");
            return;
        }
        if(!itemPrice) {
            window.toastr["error"]("Invalid itemPrice value");
            return;
        }
        if(!itemQty) {
            window.toastr["error"]("Invalid itemQty value");
            return;
        }

        var cashBoxUsdVal = $("#inCashBoxIdUsd").val();
        var cashBoxLbpVal = $("#inCashBoxIdLbp").val().replace(/,/g, "");
        if(isNaN(cashBoxLbpVal)) {
            this.pLog("red", "Value (cashBoxLbpVal) is not a number");
            return;
        }
        var totalCashBox = "";
        if(!cashBoxLbpVal && !cashBoxUsdVal) {
            this.pLog("red", "Please input Cashboxes money value");
            return;
        }
        totalCashBox = parseFloat(cashBoxUsdVal);
        if(cashBoxLbpVal) {
            totalCashBox += parseFloat(parseFloat(cashBoxLbpVal) / parseFloat(this.state.rate));
        }
        if(cmd === "Return") {
            const mainInvoiceTotalCashbox = $("#boxForTotalCashbox").val();
            if(parseFloat(-totalCashBox) > parseFloat(mainInvoiceTotalCashbox)) {
                this.pLog("red", "Cashbox value ("+-totalCashBox+") is Bigger than Original Invoice Cashbox Value ("+mainInvoiceTotalCashbox+"), Fix it");
                return;
            }
            // this.pLog("red", "Cashbox value ("+-totalCashBox+") not equal value ("+mainInvoiceTotalCashbox+"), Fix it 2");
            // return;
            axios.post("/return_invoice_item", {updateCashbox:$("#doUpdateCashbox").val(), invoiceUser:invoiceUser, status:itemPayStatus, customer:invoiceItemCustomer, origTotalCashbox:correctTotalCashbox, totalCashbox:totalCashBox, cashBoxLbpVal:cashBoxLbpVal, cashBoxUsdVal:cashBoxUsdVal, itemId:itemId, itemName:itemName, itemBasePrice:itemBasePrice, itemDiscount:itemDiscount, itemPrice:itemPrice, itemQty:itemQty}).then((ok)=>{
                window.toastr[ok.data.status](ok.data.msg);
                if(ok.data.status === "success") {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                    window.$('#table2').DataTable().ajax.reload(null, false);
                    window.$("#updateItemInvoice").modal("hide");
                    
                    $("#cashBoxUsdVal").html(parseFloat(ok.data.newCashBoxUsd).toLocaleString("en", {useGrouping:true}));
                    $("#cashBoxLbpVal").html(parseFloat(ok.data.newCashBoxLbp).toLocaleString("en", {useGrouping:true}));
                    // window.toastr[ok.data.status](ok.data.msg);
                }
            });
        } else if(cmd === "Update") {
            if(parseFloat(totalCashBox) !== parseFloat(correctTotalCashbox) && itemPayStatus === "paid") {
                this.pLog("red", "Cashbox value ("+totalCashBox+") not equal difference value ("+correctTotalCashbox+"), Fix it");
                return;
            }
            axios.post("/update_invoice_item", {invoiceUser:invoiceUser, status:itemPayStatus, customer:invoiceItemCustomer, origTotalCashbox:correctTotalCashbox, totalCashbox:totalCashBox, cashBoxLbpVal:cashBoxLbpVal, cashBoxUsdVal:cashBoxUsdVal, itemId:itemId, itemName:itemName, itemBasePrice:itemBasePrice, itemDiscount:itemDiscount, itemPrice:itemPrice, itemQty:itemQty}).then((ok)=>{
                window.toastr[ok.data.status](ok.data.msg);
                if(ok.data.status === "success") {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                    window.$('#table2').DataTable().ajax.reload(null, false);
                    window.$("#updateItemInvoice").modal("hide");
                    
                    $("#cashBoxUsdVal").html(parseFloat(ok.data.newCashBoxUsd).toLocaleString("en", {useGrouping:true}));
                    $("#cashBoxLbpVal").html(parseFloat(ok.data.newCashBoxLbp).toLocaleString("en", {useGrouping:true}));
                    // window.toastr[ok.data.status](ok.data.msg);
                }
            });
        }
    }

    setupRate = () => {
        axios.post("/get_rate").then((ok)=>{
            if(ok.data) {
                this.setState({rate:ok.data});
            }
        })
    }

    setLbpDiff = () => {
        var origTotalPrice = $("#invoiceItemOrigTotalPrice").html();
        if(this.state.invoiceStatus === "unpaid" && parseFloat(origTotalPrice) >= parseFloat(this.state.invoiceReceivedCashbox)) {
            origTotalPrice = this.state.invoiceReceivedCashbox;
        }
        if($("#updateInvoiceBtnId").html() === "Update") {
            origTotalPrice = $("#invoiceItemOrigTotalPrice").html();
        }
        const newTotalPrice = $("#invoiceItemPrice").val();
        const totalCashBox = String(parseFloat(newTotalPrice) - parseFloat(origTotalPrice));

        const usdVal = $("#inCashBoxIdUsd").val();
        if(totalCashBox) {
            const leftLbpVal = String(parseFloat(parseFloat(totalCashBox) - parseFloat(usdVal)) * parseFloat(this.state.rate));
            $("#inCashBoxIdLbp").val(parseFloat(leftLbpVal).toLocaleString('en', {useGrouping:true}));
        }
    }

    setLbpDiffInvoice = () => {
        var origTotalPrice = this.state.invoiceAmount;
        if(this.state.invoiceStatus === "unpaid") {
            origTotalPrice = this.state.invoiceReceivedCashbox;
        }
        const newTotalPrice = "0";
        const totalCashBox = String(parseFloat(newTotalPrice) - parseFloat(origTotalPrice));

        const usdVal = $("#inCashBoxIdUsdInvoice").val();
        if(totalCashBox) {
            const leftLbpVal = String(parseFloat(parseFloat(totalCashBox) - parseFloat(usdVal)) * parseFloat(this.state.rate));
            $("#inCashBoxIdLbpInvoice").val(parseFloat(leftLbpVal).toLocaleString('en', {useGrouping:true}));
        }
    }

    setLbpDiffPayInvoice = () => {
        const neededPrice = String(parseFloat(this.state.invoiceAmount) - parseFloat(this.state.invoiceReceivedCashbox));
        const usdVal = $("#cashBoxIdUsdPayInvoice").val();
        if(neededPrice) {
            const leftLbpVal = String(parseFloat(parseFloat(neededPrice) - parseFloat(usdVal)) * parseFloat(this.state.rate));
            $("#cashBoxIdLbpPayInvoice").val(parseFloat(leftLbpVal).toLocaleString('en', {useGrouping:true}));
        }
    }

    returnInvoice = () => {
        const itemId = this.state.adminIdToDel;
        const customer = this.state.adminToDel;
        const origTotalPrice = this.state.invoiceAmount;
        const branch = this.state.invoiceBranch;
        const newTotalPrice = "0";
        const correctTotalCashbox = String(parseFloat(newTotalPrice) - parseFloat(origTotalPrice));
        const itemPayStatus = this.state.invoiceStatus;
        const invoiceUser = this.state.invoiceUser;

        var cashBoxUsdVal = $("#inCashBoxIdUsdInvoice").val();
        var cashBoxLbpVal = $("#inCashBoxIdLbpInvoice").val().replace(/,/g, "");
        if(isNaN(cashBoxLbpVal)) {
            this.pLog2("red", "Value (cashBoxLbpVal) is not a number");
            return;
        }
        var totalCashBox = "";
        if(!cashBoxLbpVal && !cashBoxUsdVal) {
            this.pLog2("red", "Please input Cashboxes money value");
            return;
        }
        totalCashBox = parseFloat(cashBoxUsdVal);
        if(cashBoxLbpVal) {
            totalCashBox += parseFloat(parseFloat(cashBoxLbpVal) / parseFloat(this.state.rate));
        }
        // if(parseFloat(totalCashBox) !== parseFloat(correctTotalCashbox) && itemPayStatus === "paid") {
        //     this.pLog2("red", "Cashbox value ("+totalCashBox+") not equal difference value ("+correctTotalCashbox+"), Fix it");
        //     return;
        // }
        
        const mainInvoiceTotalCashbox = $("#boxForTotalCashbox").val();
        // if(parseFloat(totalCashBox) - parseFloat(mainInvoiceTotalCashbox) > 1 || parseFloat(mainInvoiceTotalCashbox) - parseFloat(totalCashBox) > 1) {
        //     this.pLog2("red", "Cashbox value ("+totalCashBox+") not equal value ("+mainInvoiceTotalCashbox+"), Fix it");
        //     return;
        // }
        // if(parseFloat(-totalCashBox) !== parseFloat(mainInvoiceTotalCashbox)) {
        //     this.pLog2("red", "Cashbox value ("+totalCashBox+") not equal value ("+mainInvoiceTotalCashbox+"), Fix it");
        //     return;
        // }
        // if(parseFloat(-totalCashBox) > parseFloat(mainInvoiceTotalCashbox) && itemPayStatus === "unpaid") {
        //     this.pLog2("red", "Out Cashbox ("+String(parseFloat(-totalCashBox))+") is Bigger than Current Invoice In Cashbox ("+mainInvoiceTotalCashbox+")");
        //     return;
        // }
        axios.post("/return_whole_invoice", {invoiceUser:invoiceUser, branch:branch, status:itemPayStatus, customer:customer, receivedCashBox:mainInvoiceTotalCashbox, origTotalCashbox:correctTotalCashbox, totalCashbox:totalCashBox, cashBoxLbpVal:cashBoxLbpVal, cashBoxUsdVal:cashBoxUsdVal, itemId:itemId}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$('#table1').DataTable().ajax.reload(null, false);
                // window.$('#table2').DataTable().ajax.reload(null, false);
                window.$("#returnInvoiceModal").modal("hide");
                
                $("#cashBoxUsdVal").html(parseFloat(ok.data.newCashBoxUsd).toLocaleString("en", {useGrouping:true}));
                $("#cashBoxLbpVal").html(parseFloat(ok.data.newCashBoxLbp).toLocaleString("en", {useGrouping:true}));
                // window.toastr[ok.data.status](ok.data.msg);
            }
        });
    }
    payInvoice = () => {
        const itemId = this.state.adminIdToDel;
        const customer = this.state.adminToDel;
        const origTotalPrice = this.state.invoiceAmount;
        const branch = this.state.invoiceBranch;
        const mainInvoiceTotalCashbox = $("#boxForTotalCashbox").val();
        const correctTotalCashbox = String(parseFloat(origTotalPrice) - parseFloat(mainInvoiceTotalCashbox)); // left cashbox to pay
        const itemPayStatus = this.state.invoiceStatus;
        const invoiceUser = this.state.invoiceUser;

        var cashBoxUsdVal = $("#cashBoxIdUsdPayInvoice").val();
        var cashBoxLbpVal = $("#cashBoxIdLbpPayInvoice").val().replace(/,/g, "");
        if(isNaN(cashBoxLbpVal)) {
            this.pLog3("red", "Value (cashBoxLbpVal) is not a number");
            return;
        }
        var totalCashBox = "";
        if(!cashBoxLbpVal && !cashBoxUsdVal) {
            this.pLog3("red", "Please input Cashboxes money value");
            return;
        }
        totalCashBox = parseFloat(cashBoxUsdVal);
        if(cashBoxLbpVal) {
            totalCashBox += parseFloat(parseFloat(cashBoxLbpVal) / parseFloat(this.state.rate));
        }
        if(parseFloat(totalCashBox) === 0) {
            this.pLog3("red", "Total in cashboxes 0, Nothing to pay");
            return;
        }
        axios.post("/pay_debt_invoice", {invoiceUser:invoiceUser, branch:branch, status:itemPayStatus, customer:customer, origTotalCashbox:correctTotalCashbox, totalCashbox:totalCashBox, cashBoxLbpVal:cashBoxLbpVal, cashBoxUsdVal:cashBoxUsdVal, itemId:itemId}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$('#table1').DataTable().ajax.reload(null, false);
                // window.$('#table2').DataTable().ajax.reload(null, false);
                window.$("#payInvoiceModal").modal("hide");
                
                $("#cashBoxUsdVal").html(parseFloat(ok.data.newCashBoxUsd).toLocaleString("en", {useGrouping:true}));
                $("#cashBoxLbpVal").html(parseFloat(ok.data.newCashBoxLbp).toLocaleString("en", {useGrouping:true}));
            }
        });
    }
    updateInvoiceFinalPrice = () => {
        const invoiceId = this.state.adminIdToDel;
        const user = this.state.adminToDel;
        const basePrice = $("#invoiceBasePriceIn").html();
        const finalPrice = $("#invoiceFinalPriceIn").val();
        axios.post("/update_invoice_final_price", {user:user, id:invoiceId, basePrice:basePrice, finalPrice:finalPrice}).then((ok)=>{
            window.toastr[ok.data.status](ok.data.msg);
            if(ok.data.status === "success") {
                window.$('#table1').DataTable().ajax.reload(null, false);
                window.$("#updateInvoiceFinalPrice").modal("hide");
            }
        })
    }

    render() {
        return(
            <>
            
            <div class="modal fade" id="updateInvoiceFinalPrice" role="dialog" tabindex="-1" style={{zIndex: "1051"}}>
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Invoice ID:</th>
                                        <td>{this.state.adminIdToDel}</td>
                                    </tr>
                                    <tr>
                                        <th>Invoice Base Price:</th>
                                        <td id="invoiceBasePriceIn"></td>
                                    </tr>
                                    <tr>
                                        <th>Invoice Final Price:</th>
                                        <td><input class="myInputStyle" id="invoiceFinalPriceIn" placeholder="New Final Price" type="number" /></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer" style={{alignSelf: "start", display: "block"}}>
                            <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
                                this.updateInvoiceFinalPrice();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="invoiceHistoryModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Log History of Invoice ({this.state.adminIdToDel})
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Log ID:</th>
                                        <td id="setLogIdHistory"></td>
                                    </tr>
                                    <tr>
                                        <th>Author</th>
                                        <td id="setAuthorHistory"></td>
                                    </tr>
                                    <tr>
                                        <th>Operation</th>
                                        <td id="setOperationHistory"></td>
                                    </tr>
                                    <tr>
                                        <th>Date</th>
                                        <td id="setDateHistory"></td>
                                    </tr>
                                    <tr>
                                        <th>Details</th>
                                        <td><textarea id="setDetailsHistory" style={{width: "100%", height: "250px", backgroundColor: "gainsboro", border: "groove"}}></textarea></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="payInvoiceModal" role="dialog" tabindex="-1" style={{zIndex: "1051"}}>
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Pay for Invoice ({this.state.adminIdToDel})
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Invoice ID:</th>
                                        <td>{this.state.adminIdToDel}</td>
                                    </tr>
                                    <tr>
                                        <th>Invoice Customer:</th>
                                        <td>{this.state.adminToDel}</td>
                                    </tr>
                                    <tr>
                                        <th>Invoice Status:</th>
                                        <td>{this.state.invoiceStatus}</td>
                                    </tr>
                                    <tr>
                                        <th>Invoice Amount:</th>
                                        <td>{this.state.invoiceAmount}</td>
                                    </tr>
                                    <br/>
                                    <tr>
                                        <th>Received Cashbox:</th>
                                        <td>{this.state.invoiceReceivedCashbox}</td>
                                    </tr>
                                    <tr>
                                        <th>CashBox:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <div>
                                                <input class="myInputStyle" style={{display: "inline", marginBottom: "5px"}} id="cashBoxIdUsdPayInvoice" placeholder="Received Cash" type="number" onChange={() => {
                                                }} onBlur={() => {
                                                    this.setLbpDiffPayInvoice();
                                                }} />
                                                <input class="myInputStyle" style={{display: "inline"}} id="cashBoxIdLbpPayInvoice" placeholder="Received Cash" onChange={() => {
                                                    const val = $("#cashBoxIdLbpPayInvoice").val().replace(/,/g, "");
                                                    if(val && !isNaN(val)) {
                                                        $("#cashBoxIdLbpPayInvoice").val(parseFloat(val).toLocaleString('en', {useGrouping:true}));
                                                    }
                                                }} />
                                            </div>
                                            &nbsp;
                                            <label id="rateLabelIdPayInvoice" style={{marginBottom: "0px", textAlignLast: "center"}}>Rate ({parseFloat(this.state.rate).toLocaleString("en", {useGrouping:true})})</label>
                                            &nbsp;
                                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", borderRadius: "0px", height: "25px", width: "100%"}} onClick={() => {
                                                this.setupRate();
                                            }}>Refresh Rate</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer" style={{alignSelf: "start", display: "block"}}>
                            <label id="errorLabelPayInvoice"></label>
                            <br/>
                            <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
                                this.payInvoice(); // TODO
                            }}>Pay</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="returnInvoiceModal" role="dialog" tabindex="-1" style={{zIndex: "1051"}}>
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Return Invoice ({this.state.adminIdToDel})
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Invoice ID:</th>
                                        <td>{this.state.adminIdToDel}</td>
                                    </tr>
                                    <tr>
                                        <th>Invoice Customer:</th>
                                        <td>{this.state.adminToDel}</td>
                                    </tr>
                                    <tr>
                                        <th>Invoice Status:</th>
                                        <td>{this.state.invoiceStatus}</td>
                                    </tr>
                                    <tr>
                                        <th>Invoice Amount:</th>
                                        <td>{this.state.invoiceAmount}</td>
                                    </tr>
                                    <br/>
                                    <tr>
                                        <th>Received Cashbox:</th>
                                        <td>{this.state.invoiceReceivedCashbox}</td>
                                    </tr>
                                    <tr>
                                        <th>Received USD:</th>
                                        <td>{this.state.invoiceReceivedCashboxUsd}</td>
                                    </tr>
                                    <tr>
                                        <th>Received LBP:</th>
                                        <td>{this.state.invoiceReceivedCashboxLbp}</td>
                                    </tr>
                                    <tr>
                                        <th>CashBox:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <div>
                                                <input class="myInputStyle" style={{display: "inline", marginBottom: "5px"}} id="inCashBoxIdUsdInvoice" placeholder="Received Cash" type="number" onChange={() => {
                                                }} onBlur={() => {
                                                    this.setLbpDiffInvoice();
                                                }} />
                                                <input class="myInputStyle" style={{display: "inline"}} id="inCashBoxIdLbpInvoice" placeholder="Received Cash" onChange={() => {
                                                    const val = $("#inCashBoxIdLbpInvoice").val().replace(/,/g, "");
                                                    if(val && !isNaN(val)) {
                                                        $("#inCashBoxIdLbpInvoice").val(parseFloat(val).toLocaleString('en', {useGrouping:true}));
                                                    }
                                                }} />
                                            </div>
                                            &nbsp;
                                            <label id="rateLabelIdInvoice" style={{marginBottom: "0px", textAlignLast: "center"}}>Rate ({parseFloat(this.state.rate).toLocaleString("en", {useGrouping:true})})</label>
                                            &nbsp;
                                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", borderRadius: "0px", height: "25px", width: "100%"}} onClick={() => {
                                                this.setupRate();
                                            }}>Refresh Rate</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer" style={{alignSelf: "start", display: "block"}}>
                            <label id="errorLabelInvoice"></label>
                            <br/>
                            <button class='myBtnStyle' tabIndex={0} type='button' onClick={() => {
                                this.returnInvoice(); // TODO
                            }}>Return</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="updateItemInvoice" role="dialog" tabindex="-1" style={{zIndex: "1051"}}>
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            Update Invoice Item ({this.state.itemInvoiceName}) of Invoice ({this.state.adminIdToDel})
                        </div>
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%", textAlignLast: "start"}}>
                                <tbody>
                                    <tr>
                                        <th>Item ID:</th>
                                        <td>{this.state.itemInvoiceId}</td>
                                    </tr>
                                    <tr>
                                        <th>Item Name:</th>
                                        <td>{this.state.itemInvoiceName}</td>
                                    </tr>
                                    <tr>
                                        <th>Item Base Price:</th>
                                        <td id="invoiceItemBasePrice"></td>
                                    </tr>
                                    <tr>
                                        <th>Original Total Price:</th>
                                        <td id="invoiceItemOrigTotalPrice"></td>
                                    </tr>
                                    <tr>
                                        <th>Item Pay Status:</th>
                                        <td id="invoiceItemStatus"></td>
                                    </tr>
                                    <tr>
                                        <th>Customer:</th>
                                        <td id="invoiceItemCustomer"></td>
                                    </tr>
                                    {/* <tr>
                                        <th>Customer:</th>
                                        <td id="invoiceItemCustomer"></td>
                                    </tr> */}
                                    <tr id="itemDiscountShow">
                                        <th>Item Discount:</th>
                                        <td><input class="myInputStyle" id="invoiceItemDiscount" type="number" placeholder="" onChange={() => {
                                            // this.fixInputValue("#invoiceItemDiscount");
                                            this.processInvoiceItemChanges();
                                        }} /></td>
                                    </tr>
                                    <tr id="itemTotalPriceShow">
                                        <th>Total Price:</th>
                                        <td><input class="myInputStyle" id="invoiceItemPrice" type="number" placeholder="" onChange={() => {
                                            this.fixInputValue("#invoiceItemPrice");
                                            const val = $("#invoiceItemPrice").val();
                                            const qty = $("#invoiceItemQty").val();
                                            const basePrice = String(parseFloat($("#invoiceItemBasePrice").html()) * parseFloat(qty));
                                            const disValue = String(100 - (parseFloat(val) / parseFloat(basePrice) * 100));
                                            $("#invoiceItemDiscount").val(disValue);
                                            this.processCashbox();
                                            // this.processInvoiceItemChanges(false);
                                        }}/></td>
                                    </tr>
                                    <tr id="itemQtyShow">
                                        <th>Item Qty:</th>
                                        <td><input class="myInputStyle" id="invoiceItemQty" type="number" placeholder="" onChange={() => {
                                            this.processInvoiceItemChanges();
                                        }}/></td>
                                    </tr>
                                    <br/>
                                    <tr id="doUpdateCashBoxTr">
                                        <th>Update Cashbox/Keep Invoice Amount:</th>
                                        <td>
                                            <select id="doUpdateCashbox" className="myInputStyle" onChange={() => {
                                                if($("#doUpdateCashbox").val() === "yes") {
                                                    $(".doUpdateCashboxClass").show();
                                                } else {
                                                    $(".doUpdateCashboxClass").hide();
                                                }
                                            }}>
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr className="doUpdateCashboxClass">
                                        <th>Received Cashbox:</th>
                                        <td>{this.state.invoiceReceivedCashbox}</td>
                                    </tr>
                                    <tr className="doUpdateCashboxClass">
                                        <th>CashBox:</th>
                                        <td style={{display: "flex", alignItems: "center"}}>
                                            <div>
                                                <input class="myInputStyle" style={{display: "inline", marginBottom: "5px"}} id="inCashBoxIdUsd" placeholder="Received Cash" type="number" onChange={() => {
                                                    // const val = $("#inCashBoxIdUsd").val();
                                                    // if(parseFloat(val) < 0) {
                                                    //     $("#inCashBoxIdUsd").val(0);
                                                    // }
                                                    // if(!val) {
                                                    //     $("#inCashBoxIdUsd").val(0);
                                                    // }
                                                    // if(val.length > 1 && val[0] === "0") {
                                                    //     $("#inCashBoxIdUsd").val(val.slice(1));
                                                    // }
                                                    // this.fixInputValue("#inCashBoxIdUsd");

                                                }} onBlur={() => {
                                                    this.setLbpDiff();
                                                }} />
                                                <input class="myInputStyle" style={{display: "inline"}} id="inCashBoxIdLbp" placeholder="Received Cash" onChange={() => {
                                                    const val = $("#inCashBoxIdLbp").val().replace(/,/g, "");
                                                    if(val && !isNaN(val)) {
                                                        $("#inCashBoxIdLbp").val(parseFloat(val).toLocaleString('en', {useGrouping:true}));
                                                    }
                                                    // this.fixInputValue("#inCashBoxIdLbp");
                                                }} />
                                            </div>
                                            &nbsp;
                                            <label id="rateLabelId" style={{marginBottom: "0px", textAlignLast: "center"}}>Rate ({parseFloat(this.state.rate).toLocaleString("en", {useGrouping:true})})</label>
                                            &nbsp;
                                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", borderRadius: "0px", height: "25px", width: "100%"}} onClick={() => {
                                                this.setupRate();
                                            }}>Refresh Rate</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer" style={{alignSelf: "start", display: "block"}}>
                            <label id="errorLabel"></label>
                            <br/>
                            <button class='myBtnStyle' id="updateInvoiceBtnId" tabIndex={0} type='button' onClick={() => {
                                this.updateInvoiceItem(); // TODO
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="modal fade" id="suppliersLogsHistory" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg" style={{maxWidth: "95%"}}>
                    <div class="modal-content" style={{backgroundColor: "gainsboro"}}>
                        <div class="modal-header">
                            Invoice ({this.state.adminIdToDel}) Details of Customer ({this.state.adminToDel})
                        </div>
                        <div class="modal-body">
                            <input id="boxForSupplierId" style={{display: "none"}} />
                            <input id="boxForTotalCashbox" style={{display: "none"}} />
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "left"}} onClick={() => {
                                window.$('#table2').DataTable().ajax.reload(null, false);
                            }}>Refresh</button>
                            <br/>
                            <br/>
                            <table class="table table-report dt-responsive -mt-2" id="table2" style={{width: "100%"}}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>User</th>
                                        <th>Item Name</th>
                                        <th>Item ID</th>
                                        <th>Item Cost</th>
                                        <th>Item Price</th>
                                        <th>Item Discount</th>
                                        <th>Final Price</th>
                                        <th>Sold Qty</th>
                                        <th>New Item Qty</th>
                                        <th>Total Price</th>
                                        <th>Profit</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                        <th>Branch</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="deleteBranchModal" role="dialog" tabindex="-1">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            Deleting Supplier
                        </div>
                        <div class="modal-body">
                            Are you sure you want to remove Supplier ({this.state.adminToDel}) ?
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.deleteBranch();
                            }}>Yes</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                window.$("#deleteBranchModal").modal("hide");
                            }}>No</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="updateBalanceModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Name:</th>
                                        <td id="setSupplierName"></td>
                                    </tr>
                                    <tr>
                                        <th>Balance:</th>
                                        <td id="setSupplierBalance"></td>
                                    </tr>
                                    <tr>
                                        <th>Balance to change:</th>
                                        <td><input class="myInputStyle" type="number" id="balanceToChangeIn"/></td>
                                    </tr>
                                    <tr>
                                        <th>Note:</th>
                                        <td><input class="myInputStyle" id="balanceChangeNote"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right", color: "red"}} onClick={() => {
                                this.updateSupplierBalance("-");
                            }}>Remove Balance</button>
                            <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.updateSupplierBalance("+");
                            }}>Add Balance</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="addBranchModal" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-body">
                            <table className="table-modal" style={{width: "100%"}}>
                                <tbody>
                                    <tr>
                                        <th>Name:</th>
                                        <td><input class="myInputStyle" id="nameInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Phone:</th>
                                        <td><input class="myInputStyle" type="number" id="phoneInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Country:</th>
                                        <td><input class="myInputStyle" id="countryInput"/></td>
                                    </tr>
                                    <tr>
                                        <th>Balance:</th>
                                        <td><input class="myInputStyle" type="number" id="balanceInput"/></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            <button class='myBtnStyle' id='addAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.addBranch();
                            }}>Add</button>

                            <button class='myBtnStyle' id='editAdminBtn' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                                this.editBranch();
                            }}>Update</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="FusePageCarded-header container">
                <div style={{flexDirection: "column"}} className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
                    <span className="MuiTypography-root MuiTypography-body1 flex text-24 md:text-32 font-extrabold tracking-tight muiltr-1niqtu4-MuiTypography-root" delay={300} style={{transform: 'none'}}>Invoices</span>
                </div>
            </div>

            <div style={{padding: "0px 10px 0px 10px"}}>

                {this.state.addAdminsBtn}

                <button class='myBtnStyle' tabIndex={0} type='button' style={{float: "right"}} onClick={() => {
                    window.$('#table1').DataTable().ajax.reload(null, false);
                }}>Refresh</button>
                <br/>
                <br/>
                <table class="table table-report dt-responsive -mt-2" id="table1" style={{width: "100%", fontSize: "13px"}}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>User</th>
                            <th>Sell Type</th>
                            <th>Pay Type</th>
                            <th>Customer</th>
                            <th>Base Price</th>
                            <th>Discount</th>
                            <th>Final Price</th>
                            <th>Cashbox USD/LBP</th>
                            <th>Profit</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Branch</th>
                            <th>Note</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
{/*
            <table id="table1" className="MuiTable-root MuiTable-stickyHeader min-w-xl muiltr-5g2394-MuiTable-root" aria-labelledby="tableTitle">
                <thead className="MuiTableHead-root muiltr-15wwp11-MuiTableHead-root">
                    <tr className="MuiTableRow-root MuiTableRow-head h-48 sm:h-64 muiltr-1yv0chr-MuiTableRow-root">
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            ID
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Username
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Access
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Status
                        </th>
                        <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-stickyHeader MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center z-99 muiltr-ls8dwh-MuiTableCell-root" scope="col">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody className="MuiTableBody-root muiltr-apqrd9-MuiTableBody-root">
                    <tr className="MuiTableRow-root MuiTableRow-hover h-72 cursor-pointer muiltr-1yv0chr-MuiTableRow-root" role="checkbox" aria-checked="false" tabIndex={-1}>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-paddingNone MuiTableCell-sizeMedium w-40 md:w-64 text-center muiltr-1g67rzx-MuiTableCell-root">
                        </td>
                    </tr>
                </tbody>
            </table>
*/}
            </>
        )
    }
}

export default main;
